<template>
    <div>
        <el-dialog :title="title" :visible.sync="isShow"  @close="closeDialog">
            <el-form :model="form" ref="dialogForm" v-if="dialogType=='add'">
                <div v-for="(item, index) in formItem" :key="index">
                    <el-form-item label-width="120px" 
                        :label="item.label" 
                        :prop="item.required?item.keys:''" 
                        :rules="item.required ? ({ required: item.required, message: item.message, trigger: item.trigger}) : { required: false }"
                    >
                        <el-input v-if="!item.options" v-model="form[item.keys]" autocomplete="off"
                            :placeholder="'请输入' + item.label"></el-input>
                        <el-select v-if="item.options==true" v-model="form[item.keys]" :placeholder="optionPlaceholder">
                            <el-option v-for="(ite, ind) in optionProps" :label="ite.label" :value="ite.value"
                                :key="ind"></el-option>
                        </el-select>
                    </el-form-item>
                </div>

            </el-form>

            <el-form :model="form" ref="dialogForm" v-if="dialogType=='updata'">
                <div v-for="(item, index) in formItem" :key="index">
                    <el-form-item label-width="120px" 
                        :label="item.label" 
                        :prop="item.required?item.keys:''" 
                        :rules="item.required ? ({ required: item.required, message: item.message, trigger: item.trigger}) : { required: false }"
                    >
                        <el-input v-if="!item.options" v-model="form[item.keys]" autocomplete="off"
                            :placeholder="'请输入' + item.label"></el-input>
                        <el-select v-if="item.options==true" v-model="form[item.keys]" :placeholder="optionPlaceholder">
                            <el-option v-for="(ite, ind) in optionProps" :label="ite.label" :value="ite.value"
                                :key="ind"></el-option>
                        </el-select>
                    </el-form-item>
                </div>

            </el-form>


            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="sureDialog">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '标题',
            require: true
        },
        optionPlaceholder: {
            type: String,
            default: '请选择',
            require: false
        },
        formItem: {
            type: Array,
            default: [],
            require: false
        },
        optionProps: {
            type: Array,
            default: [],
            require: false
        },
        dialogFormVisible: {
            type: Boolean,
            default: false,
            require: true
        },
        dialogType:{
            type:String,
            default:'add',
            require:false
        }
    },
    data() {
        return {
            form: {

            },
            isShow:false
        }
    },
    watch: {
        // 观察父级组件的showFlag，并将showFlag的最新值设置给dialogVisible
        dialogFormVisible (newVal, oldVal) {
            this.isShow = newVal
        }
    },
    methods: {
        cancel() {
            this.form = {}
            this.$emit('cancel-dialog', false)
            this.$refs['dialogForm'].resetFields();
        },
        sureDialog() {
            this.$refs['dialogForm'].validate((valid) => {
                // console.log(valid);
                if (valid) {
                    this.$emit('sure-dialog', this.form)
                    this.form = {}
                    this.$refs['dialogForm'].resetFields();
                }
            });
        },
        closeDialog(){
            this.form = {}
            this.$emit('closeChildDialog')
            this.$refs['dialogForm'].resetFields();
        }
    },
    created() {
        this.form = this.formItem.reduce((result, item) => {
            result[item.keys] = '';
            return result;
        }, {});
    }
}
</script>

<style></style>